<h1>Perfume DB</h1>
Perfumeの過去のデータをWebアプリから扱いやすくした形で保存するプロジェクトです。<br>
PCでご覧の場合は左側のメニューから、<br>
スマホでご覧の場合は画面上部の「≡」アイコンからメニューを開いてお楽しみください。

<h2>作者</h2>
<p>Pittan(<a href="https://twitter.com/pittanko_pta" target="_blank">@pittanko_pta</a>)</p>

<h2>FAQ</h2>
<h3>どういう基準でデータにしているの？</h3>
<p>
  ■シングル・アルバム情報<br>
  公式サイトのディスコグラフィに載っている情報をベースにしています。
</p>
<p>
  ■セットリスト情報<br>
  - Perfume LIVE DATA BOOK<br>
  - #prfm セットリスト(@aoky_ 作)<br>
  の2つをベースにしています。
</p>

<h3>同じ曲の微妙に異なるバージョンはどうしているの？</h3>
<p>
  基本的には公式サイトに従っています。<br>
  <br>
  TOKYO GIRLのシングル版とアルバム版ではサビ部分の「色とりどりの恋」の歌い方が異なるため、<br>
  別バージョンではないかとツッコミが入りそうですが、ホームページ上では同じタイトルのため同じものとしています。<br>
  <br>
  edgeのシングル版とアルバム版(⊿-mix)のように、明確に違うものである場合（公式サイトでも異なる表記をしてあるもの）<br>
  については、別のものとして扱っています。<br>
  Instrumentalについても同様になります。
</p>

<h3>なんか間違ってるんだけど</h3>
<p>
  手作業で打ち込んでいるため、セットリスト等の情報が誤っている可能性があります。<br>
  作者にお声掛けください。可能な範囲で対応します。<br>
  または、このプロジェクトは<a href="https://github.com/Pittan/perfume-database" target="_blank">GitHub</a>で管理されています。直接Contributeしていただいても問題ありません。
</p>

<h3>セットリストについてなんか変じゃない？</h3>
<p>
  例えば edge の場合、多くは ⊿-mix が披露されているケースが多いと思いますが、<br>
  公式のLIVE DATA BOOKではすべて無印のedgeで統一されており、そちらの表現に統一しています。<br>
  とはいえ、データ上で誤りがある場合もあるので、曲詳細画面の関連ライブリストには<br>
  異なるバージョンを含めたすべての曲について、披露されたイベントを表示しています。<br>
</p>

<h3>Tourの定義は？</h3>
<p>
  実際に全国各地をまわった、という一般的な概念とは少し異なり<br>
  ここでは「同名の公演を複数回行った」場合ではツアーとして定義しています。<br>
  同名の公演がリストに並びすぎる場合を考慮してこの基準にしています。 (例: ディスコ！ディスコ！ディスコ！は同じ場所で2日間公演を行いましたが、ツアーとしてカウントします。)<br>
  <br>
  ただし、フェスの出演でDay1は千葉で、Day2は大阪のような出演になる場合はツアーとしてはカウントしません。<br>
  Perfumeが主催者となるイベントかどうかも基準にしています。<br>
  (例: Perfume FES!!はツアーですが、サマソニはツアーではない)<br>
</p>
