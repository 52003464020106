// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  analytics: { id: undefined },
  appleMusic: {
    // tslint:disable
    developerToken: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdVQkpQOTlTNk4ifQ.eyJpc3MiOiI5MzVVVUhLRU45IiwiZXhwIjoxNTkxMTkxMjM3LCJpYXQiOjE1ODM0MTUyMzd9.lB-FpjYw6RBamHyzynXkLbx0Yoo0zpgcyunq0oaL0tkcEgU542Q3Du63PrZlr2CBcQ8lti1c-56xKzCKGMmyPQ',
    // tslint:enable
    name: 'Perfume DB - dev',
    build: '2020.04.26'
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
