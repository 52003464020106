<div class="backdrop" [class.gray]="menu.isMobileView" (click)="closeIfNeeded()">
  <div class="container">
    <nav (click)="preventClick($event)">
      <a routerLink="/songs" routerLinkActive="current" (click)="closeIfNeeded()">Songs</a>
      <a routerLink="/albums" routerLinkActive="current" (click)="closeIfNeeded()">Albums</a>
      <a routerLink="/tours" routerLinkActive="current" (click)="closeIfNeeded()">Tours</a>
      <a routerLink="/events" routerLinkActive="current" (click)="closeIfNeeded()">Events</a>
    </nav>

    <div class="view-header" (click)="preventSliderClick($event)">
      <label class="inst-switch" for="inst-switch">
        Show Spoiler
        <label class="switch">
          <input type="checkbox" id="inst-switch" [(ngModel)]="allowSpoiler" (ngModelChange)="allowSpoilerChanged($event)">
          <span class="slider"></span>
        </label>
      </label>
    </div>
  </div>
</div>

